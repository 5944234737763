@import "variables";
@import 'mixins.scss';

html {
  font-size: $base-font-size;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  font-size: $default-font-size;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: $default-body-color;
  font-family: $default-font-family;
  font-weight: $default-font-weight;
  line-height: $default-line-height;
  color: $default-font-color;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
}
@media print{
  body {
    min-height: auto;
    background: $color-primary-light;
    zoom: 100%;
  }
  @page {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

img[src=""] {
  display: none;
  position: relative;
}

.-group:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

p, ul li, ol li {
  font-weight: $font-weight-normal;
  font-size: $font-size-xs;

  &.text-semi {
    font-weight: $font-weight-semi-bold;
  }
}

ins {
  text-decoration: none;
}

.rb-table {
  &__head {
    background-color: $color-neutral-gray-7;

    tr th {
      background-color: $color-neutral-gray-7;
      border: 1px solid $color-neutral-gray-6 !important;
      font-size: $font-size-copy-sm;
      color: $color-neutral-gray-4;
      padding: 8px;
      vertical-align: middle;
      text-transform: uppercase;
    }
  }

  &__body {
    tr td {
      font-size: $font-size-sm;
      color: $color-neutral-gray-1;
      border: 1px solid $color-neutral-gray-6;
      padding: 8px;
      vertical-align: middle;
    }

    tr:nth-of-type(even) {
      background-color: $color-neutral-gray-7;
    }

    tr:nth-of-type(odd) {
      background-color: $color-neutral-gray-8;
    }

    tr {
      &:hover,
      &:focus,
      &:active,
      &.selected {
        cursor: pointer;
        background-color: $color-neutral-gray-6;

        td:first-child {
          border-left: 4px solid $color-primary-pink;
        }
      }
    }
  }
}

.rb-input-with-icon {
  &__container {
    background-color: $color-neutral-gray-8;
    position: relative;
    border-radius: 4px;
    border: 1px solid $color-neutral-gray-6;
    padding: 3px 50px 3px 10px;
    max-width: 528px;
    flex-grow: 1;
  }

  &__input {
    background-color: $color-neutral-gray-8;
    outline: none;
    height: 40px;
    font-size: 15px;
    border: 0;
    width: 98%;
    color: $color-neutral-gray-1;
    margin-left: 32px;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-neutral-gray-8;
      color: $color-neutral-gray-1;
    }
  }

  &__left-icon {
    position: absolute;
    left: 12px;
    top: 12px;
    background-color: $color-neutral-gray-1 !important;
  }

  &__right-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: $color-neutral-gray-1 !important;
  }
}

.hand{
  cursor: pointer;
}
.rb-border-right{
  border-right: 1px solid $color-neutral-gray-10;
}
.rb-border-left{
  border-left: 1px solid $color-neutral-gray-10;
}
.rb-border-top{
  border-top: 1px solid $color-neutral-gray-10;
}
.rb-border-bottom{
  border-bottom: 1px solid $color-neutral-gray-10;
}
::ng-deep{
  .ql-snow{
    border-color: $color-neutral-gray-7 !important;
  }
}

.hide {
  visibility: hidden;
}

