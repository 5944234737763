@import "../variables";
@import "font";

html {
  font-size: 62.5%; // Now 10px = 1rem
}

body {
  margin: 0 auto;
  color: $color-primary-dark;
  @extend .font-30;
}
