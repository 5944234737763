@import "../variables";
select.rb-select{
  background-color: $color-neutral-gray-8;
  border:1px solid $color-neutral-gray-6;
  border-radius:2px;
  font-size:$font-size-sm;
  color:$color-neutral-gray-3;
  width:157px;
  height:37px;
  padding-left:12px;
  background-image:
    linear-gradient(45deg, transparent 50%, $color-neutral-gray-3 50%),
    linear-gradient(135deg, $color-neutral-gray-3 50%, transparent 50%),
    linear-gradient(to right, $color-neutral-gray-8, $color-neutral-gray-8);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  &.table-select {
    border: none;
    background-color: transparent;
    width: 100%;
    background-image: linear-gradient(45deg, transparent 50%, #D3D3D3 50%),
    linear-gradient(135deg, #D3D3D3 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  }
}
