@import '~src/styles/variables';

$icon-path: '~src/assets/icons/button/';
// BUTTON
%rb-btn {
  position: relative;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  font-size: $font-size-copy-lg;
  font-weight: $font-weight-semi-bold;
  padding: #{$spacing-xs + $spacing-xxs} $spacing-lg;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &:after {
    display: block;
    content: attr(data-caption);
    font-weight: $font-weight-bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }

  &:hover,
  &:focus {
    font-weight: $font-weight-semi-bold;
    opacity: 0.8;

    &:before {
      transform: translateX(300px) skewX(-15deg);
      opacity: 0.6;
    }
  }

  &:active {
    -webkit-box-shadow: inset 5px 5px 10px -5px rgba($color-neutral-gray-9, 0.6);
    -moz-box-shadow: inset 5px 5px 10px -5px rgba($color-neutral-gray-9, 0.6);
    box-shadow: inset 5px 5px 10px -5px rgba($color-neutral-gray-9, 0.6);
  }
}

%btn-disabled {
  cursor: none;
  background: $color-neutral-gray-7;
  color: $color-neutral-gray-4;
  border-width: 1px;
  border-style: solid;
  border-color: $color-neutral-gray-7;
  opacity: 0.4;

  &:hover,
  &:focus {
    background: $color-neutral-gray-7;
    border-color: $color-neutral-gray-7;
    color: $color-neutral-gray-4;
    cursor: not-allowed;
    font-weight: initial;
  }

  &:before,
  &:after {
    display: none;
  }
}

@mixin setSecondaryColor($color) {
  @extend %rb-btn;
  background: transparent;
  border-color: $color;
  color: $color;

  &.selected,
  &:hover,
  &:focus,
  &:active,
  &.-active,
  &.-selected,
  &.active{
    border-color: $color;
    background-color: $color;
    color: $color-primary-light;

    [class^="rb-icon-"], [class*=" rb-icon-"], .rb-icon {
      background-color: $color-primary-light;
    }
  }

  &.-disabled {
    @extend %btn-disabled;
  }
  [class^="rb-icon-"], [class*=" rb-icon-"], .rb-icon {
    background-color: $color;
  }
}

@mixin setPrimaryColor($color) {
  @extend %rb-btn;
  background: $color;
  border-color: $color;
  color: $color-neutral-gray-1;

  &:hover,
  &:focus {
    background: $color;
    border-color: $color;
    color: $color-neutral-gray-1;
  }

  &:active {
    background: $color;
  }

  &.-disabled {
    @extend %btn-disabled;
  }
}

// PRIMARY
//primary green
button.rb-btn-primary-green, label.rb-btn-primary-green {
  @include setPrimaryColor($color-primary-green);
}

//primary light green
button.rb-btn-primary-light-green, label.rb-btn-primary-light-green {
  @include setPrimaryColor($color-secondary-green);
}

//primary blue
button.rb-btn-primary-blue, label.rb-btn-primary-blue {
  @include setPrimaryColor($color-secondary-blue);
}

//primary indigo
button.rb-btn-primary-indigo, label.rb-btn-primary-indigo {
  @include setPrimaryColor($color-secondary-indigo);
}

//primary orange
button.rb-btn-primary-orange, label.rb-btn-primary-orange {
  @include setPrimaryColor($color-secondary-orange);
}

//primary yellow
button.rb-btn-primary-yellow, label.rb-btn-primary-yellow {
  @include setPrimaryColor($color-primary-yellow);
}

//primary-purple
button.rb-btn-primary-purple, label.rb-btn-primary-purple {
  @include setPrimaryColor($color-secondary-purple);
}

//primary-red
button.rb-btn-primary-red, label.rb-btn-primary-red {
  @include setPrimaryColor($color-secondary-red);
}

//primary-teal
button.rb-btn-primary-teal, label.rb-btn-primary-teal {
  @include setPrimaryColor($color-secondary-teal);
}

//primary-pink
button.rb-btn-primary-pink, label.rb-btn-primary-pink {
  @include setPrimaryColor($color-primary-pink);
}

button.rb-btn-primary-cyan, label.rb-btn-primary-cyan {
  @include setPrimaryColor($color-secondary-cyan);
}

// SECONDARY
button.rb-btn-secondary-blue, label.rb-btn-secondary-blue {
  @include setSecondaryColor($color-secondary-blue);
}

button.rb-btn-secondary-indigo, label.rb-btn-secondary-indigo {
  @include setSecondaryColor($color-secondary-indigo);
}

button.rb-btn-secondary-orange, label.rb-btn-secondary-orange {
  @include setSecondaryColor($color-secondary-orange);
}

button.rb-btn-secondary-purple, label.rb-btn-secondary-purple {
  @include setSecondaryColor($color-secondary-purple);
}

button.rb-btn-secondary-red, label.rb-btn-secondary-red {
  @include setSecondaryColor($color-secondary-red);
}

button.rb-btn-secondary-teal, label.rb-btn-secondary-teal {
  @include setSecondaryColor($color-secondary-teal);
}

button.rb-btn-secondary-green, label.rb-btn-secondary-green {
  @include setSecondaryColor($color-primary-green);
}

button.rb-btn-secondary-light-green, label.rb-btn-secondary-light-green {
  @include setSecondaryColor($color-secondary-green);
}

button.rb-btn-secondary-deep-orange, label.rb-btn-secondary-deep-orange {
  @include setSecondaryColor($color-primary-deep-orange);
}

button.rb-btn-secondary-pink, label.rb-btn-secondary-pink {
  @include setSecondaryColor(#{$color-primary-pink});
}

button.rb-btn-secondary-yellow, label.rb-btn-secondary-yellow {
  @include setSecondaryColor(#{$color-primary-yellow});
}

button.rb-btn-secondary-cyan, label.rb-btn-secondary-cyan {
  @include setSecondaryColor(#{$color-secondary-cyan});
}
button.rb-btn-secondary-gray {
  @include setSecondaryColor(#{$color-neutral-gray-6});
  color: $color-neutral-gray-1;
}

button.rb-btn-secondary-dark {
  @extend %rb-btn;
  background: transparent;
  border-color: $color-neutral-gray-7;
  color: $color-neutral-gray-1;

  &.selected,
  &:hover,
  &:focus,
  &:active {
    border-color: $color-neutral-gray-7;
    background-color: $color-neutral-gray-8;
    color: $color-neutral-gray-1;
  }

  &.-disabled {
    @extend %btn-disabled;
  }
}

//primary dark
button.rb-btn-primary-dark {
  @extend %rb-btn;
  background: $color-neutral-gray-7;
  border-color: $color-neutral-gray-7;
  color: $color-primary-light;

  &:hover,
  &:focus {
    background: $color-neutral-gray-8;
    border-color: $color-neutral-gray-8;
    color: $color-primary-light;
  }

  &:active {
    background: $color-neutral-gray-8;
    border-color: $color-neutral-gray-8;
    color: $color-primary-light;
  }

  &.-disabled {
    @extend %btn-disabled;
  }
}

button.rb-btn-lg {
  width: 250px;
  height: 70px;
  font-size: $font-size-lg;
  margin-top: 16px;
  margin-right: 16px;
}

button.rb-btn-md {
  width: auto;
  height: 30px;
  padding: $spacing-xxs $spacing-sm;
  font-size: $font-size-sm;
}

button.rb-btn-sm {
  width: 200px;
  height: 70px;
}

button.rb-btn-circle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: $color-primary-light;
  padding: 0;
  font-weight: $font-weight-semi-bold;
}

button.-selected {
  background: $color-primary-pink !important;
  border-color: $color-primary-pink !important;
  color: $color-primary-light !important;
}

button.rb-btn-time-dark, label.rb-btn-time-dark {
  @extend %rb-btn;
  background: $color-neutral-gray-8;
  border: 1px solid $color-neutral-gray-7;
  color: $color-primary-light;
  font-size: $font-size-sm;
  width: 128px;

  &:hover,
  &:focus,
  &.active{
    background: $color-primary-pink;
    border: 1px solid $color-primary-pink;
    color: $color-primary-light;
  }

  &:active {
    background: $color-primary-pink;
    border: 1px solid $color-primary-pink;
    color: $color-primary-light;
  }

  &.-disabled {
    @extend %btn-disabled;
  }
}

button.rb-btn-back {
  @extend %rb-btn;
  background: $color-neutral-gray-7;
  border: 1px solid $color-neutral-gray-7;
  color: $color-primary-light;
  font-size: $font-size-sm;

  &:hover,
  &:focus {
    background: $color-neutral-gray-7;
    border: 1px solid $color-neutral-gray-7;
    color: $color-primary-light;
  }
}

button.rb-btn-next {
  @extend %rb-btn;
  background: $color-primary-green;
  border: 1px solid $color-primary-green;
  color: $color-primary-light;
  font-size: $font-size-sm;
  width: 128px;

  &:hover,
  &:focus {
    background: $color-primary-green;
    border: 1px solid $color-primary-green;
    color: $color-primary-light;
  }

  &.-disabled {
    @extend %btn-disabled;
  }
}

button.btn-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

