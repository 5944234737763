@import '../variables';

.alert-dark {
  font-weight: 600;
  margin-top: 20px;
  color: $color-primary-light;
  background-color: $color-secondary-red;
  border-color: $color-primary-light;
}

.tooltip-class .tooltip-inner {
  background-color: $color-neutral-gray-9;
  color: $color-primary-light;
  font-size: 175%;
}

.tooltip-class .arrow::before {
  border-top-color: $color-neutral-gray-9;
}
.tooltip-class .arrow::after {
  border-top-color: $color-neutral-gray-9;
}

.pointer {
  cursor: pointer;
}

.popover-class {
  background: $color-neutral-gray-9;
  color: $color-primary-light;
  font-size: 110%;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.64);
}

.popover-class .popover-header {
  background: $color-neutral-gray-9;
  color: $color-neutral-gray-4;
  border-bottom: 1px solid $color-neutral-gray-6;
  font-size: 110%;
}

.popover-class .popover-body {
  background: $color-neutral-gray-9;
  color: $color-neutral-gray-4;
  padding: 8px;
}

.popover-class .popover-header::before {
  background: $color-neutral-gray-9;
}

.popover-class .arrow::before {
  border-top-color: $color-neutral-gray-9;
}

.popover-class .arrow::after {
  border-top-color: $color-neutral-gray-9;
}
