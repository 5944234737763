@import "../variables";

.font {
  font-family: $font-family-sans-serif;
}

.font-regular {
  font-weight: $font-weight-normal;
}
.font-semi{
  font-weight: $font-weight-semi-bold;
}

.font-bold {
  font-weight: $font-weight-bold;
}

.font-capital {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.font-20 {
  @extend .font;
  @extend .font-regular;
  font-size: $font-size-20;
}

.font-30 {
  @extend .font;
  @extend .font-regular;
  font-size: $font-size-30;
}

.font-40 {
  @extend .font;
  @extend .font-regular;
  font-size: $font-size-40;
}

.font-50 {
  @extend .font;
  @extend .font-regular;
  font-size: $font-size-50;
}

.font-60 {
  @extend .font;
  @extend .font-regular;
  font-size: $font-size-60;
}

.disabled-text {
  color: $color-neutral-gray-6 !important;
}
.-header{
  font-size: $font-size-30;
  font-weight: $font-weight-semi-bold;
  color: $color-neutral-gray-5;
  text-transform: uppercase;
}
