@import "variables";
@import "icons";
@import "design-system/buttons";

$modal-width: 768px;
$modal-max-width: 820px;
$modal-max-height: 640px;
$modal-xl-width: 1200px;
$modal-lg-width: 970px;
$modal-md-width: 820px;
$modal-sm-width: 768px;
$modal-sm-height: 300px;
$header-height: 40px;
$footer-height: 80px;

@mixin modal-icon($col:transparent) {
  [class^='rb-icon'] {
    float: left;
    font-size: $font-weight-bold;
    margin: 0 $spacing-xs 0 0;
    color: $col;
  }
}

@mixin modal-dimensions($width:$modal-width, $height:auto) {
  width: $width;
  height: $height;
  max-height: $modal-max-height;
}

.rb-dialog {
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  padding: 0;
  background-color: $color-neutral-gray-9;
  @include modal-dimensions();

  &.modal-xl {
    @include modal-dimensions($modal-xl-width);
  }

  &.modal-lg {
    @include modal-dimensions($modal-lg-width);
  }

  &.modal-md {
    @include modal-dimensions($modal-md-width);
  }

  &.modal-sm {
    @include modal-dimensions($modal-sm-width);
  }

  &.alert, &.warning, &.info, &.success{
    max-width: 440px;
    .action-btn-group {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: $spacing-md;

      .-ok {
        @extend %rb-btn;
        min-width: 86px;
        width: auto;
        height: 48px;
        padding:0px 16px !important;
        color: $color-neutral-gray-1;
        background: $color-primary-green;
        border: 1px solid $color-primary-green;
      }

      .-cancel {
        @extend %rb-btn;
        min-width: 118px;
        width: auto;
        height: 48px;
        border: 1px solid $color-neutral-gray-7;
        color: $color-neutral-gray-1;
        background: $color-neutral-gray-9;
        padding:0px 16px !important;
        margin-right: $spacing-md;
      }
    }
  }

  &.alert {
    .rb-modal {
      .rb-modal-header {
        @include modal-icon($color-secondary-red);
        color: $color-secondary-red !important;
        [class^='rb-icon'] {
          @extend .rb-icon-alert;
        }
      }
    }
  }

  &.warning {
    max-width: 440px;

    .rb-modal {
      .rb-modal-header {
        @include modal-icon($color-secondary-orange);
        color: $color-secondary-orange !important;

        [class^='rb-icon'] {
          @extend .rb-icon-warning;
        }
      }
    }
  }

  &.info {
    max-width: 440px;

    .rb-modal {
      .rb-modal-header {
        @include modal-icon($color-secondary-blue);
        color: $color-secondary-blue !important;
        [class^='rb-icon'] {
          @extend .rb-icon-info;
        }
      }
    }
  }

  &.success {
    max-width: 440px;

    .rb-modal {
      .rb-modal-header {
        @include modal-icon($color-secondary-green);
        color: $color-secondary-green !important;

        [class^='rb-icon'] {
          @extend .rb-icon-success;
        }
      }
    }
  }

  .rb-dialog-btn-close {
    float: right;
    margin: auto;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: $color-neutral-gray-4;
    opacity: 1;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding-left: 3.5px;
    padding-top: 2px;
  }

  .rb-modal-header {
    background-color: $color-neutral-gray-8;
    padding: $spacing-md;
    height: auto;
    width: 100%;
    display: block;
    vertical-align: middle;

    .modal-header-copy {
      font-size: $font-size-md;
      line-height: $font-size-xl;
      max-width: 95%;
      color: inherit;
      font-weight: $font-weight-semi-bold;
      @media (max-width: 600px) {
        font-size: $font-size-md;
      }
    }
  }

  .rb-modal-body {
    padding: $spacing-md;
    font-size: $font-weight-normal;
    display: block;
    flex-direction: column;
    overflow: auto;
    color: $color-neutral-gray-3;
  }

  .rb-modal-footer {
    padding: 0 $spacing-md $spacing-md;
  }

  .rb-modal {
    position: relative;
    padding: 0;
    margin: 0;
  }

  .button-set {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 24px;

    .-ok {
        @extend %rb-btn;
        min-width: 105px;
        height: 48px;
        padding:0px !important;
        color: $color-neutral-gray-1;
        background: $color-primary-green;
        border: 1px solid $color-primary-green;
      &.-disabled{
        opacity: .5;
      }
    }
  }
}

.modal-overlay, .modal-overlay-body {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 62px;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  cursor: pointer;
  opacity: 0.8;
}

.modal-overlay-body {
  top: 0;
}

@media print {
  .modal-overlay, .modal-overlay-body {
    display: none;
  }
  .rb-dialog{
    position: absolute;
    top: 0px !important;
    left: 0px !important;
    transform: translate(0%, 0%);
    min-width: 100vw;
    background-color: #fff !important;
  }
  .rb-modal-body{
    background-color: #fff !important;
  }
}
