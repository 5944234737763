@import "variables";

@mixin text-xxs {
  font-size: $font-size-xxs;
}

@mixin text-xs {
  font-size: $font-size-xs;
}

@mixin text-sm {
  font-size: $font-size-sm;
}

@mixin text-md {
  font-size: $font-size-md;
}

@mixin text-lg {
  font-size: $font-size-lg;
}

@mixin text-xl {
  font-size: $font-size-xl;
}

@mixin text-xxl {
  font-size: $font-size-xxl;
}

@mixin flex($start, $end, $wrap) {
  display: flex;
}

@mixin widths {
  @for $i from 1 through 100 {
    .-w#{$i} {
      width: #{$i + "%"} !important;
    }
  }
}

@mixin spacing {
  @for $i from 1 through 48 {

    .-m#{$i} {
      margin: #{$i}px !important;
    }

    .-mt#{$i} {
      margin-top: #{$i}px !important;
    }

    .-mb#{$i} {
      margin-bottom: #{$i}px !important;
    }

    .-ml#{$i} {
      margin-left: #{$i}px !important;
    }

    .-mr#{$i} {
      margin-right: #{$i}px !important;
    }

    .-p#{$i} {
      padding: #{$i}px !important;
    }

    .-pt#{$i} {
      padding-top: #{$i}px !important;
    }

    .-pb#{$i} {
      padding-bottom: #{$i}px !important;
    }

    .-pl#{$i} {
      padding-left: #{$i}px !important;
    }

    .-pr#{$i} {
      padding-right: #{$i}px !important;
    }

    .-h#{$i} {
      height: #{$i}px !important;
      display: block;
      clear: both;
    }

  }
}

@include spacing;
@include widths;

@mixin for-desktop-sm {
  @media (max-width: 1200px) { @content; }
}


@mixin for-tablet-lg {
  @media (max-width: 1024px) { @content; }
}

@mixin for-tablet {
  @media (max-width: 970px) { @content; }
}

@mixin for-mobile {
  @media (max-width: 840px) { @content; }
}

@mixin for-mobile-xs{
  @media (max-width: 767px) { @content; }
}

@mixin for-mobile-xxs{
  @media (max-width: 599px) { @content; }
}
