@import '../variables';
//background
.bg-color-gray-1{
   background-color: $color-neutral-gray-1;
 }
.bg-color-gray-2{
  background-color: $color-neutral-gray-3;
}
.bg-color-gray-3{
  background-color: $color-neutral-gray-3;
}
.bg-color-gray-4{
  background-color: $color-neutral-gray-4;
}
.bg-color-gray-5{
  background-color: $color-neutral-gray-5;
}
.bg-color-gray-6{
  background-color: $color-neutral-gray-6;
}
.bg-color-gray-7{
  background-color: $color-neutral-gray-7;
}
.bg-color-gray-8{
  background-color: $color-neutral-gray-8;
}
.bg-color-gray-9{
  background-color: $color-neutral-gray-9;
}
.bg-color-gray-10{
  background-color: $color-neutral-gray-10;
}
//color-primary COLORS
.bg-color-beige{
  background-color: $color-primary-beige;
}
.bg-color-dark{
  background-color: $color-primary-dark;
}
.bg-color-green{
  background-color: $color-primary-green;
}
.bg-color-light{
  background-color: $color-primary-light;
}
.bg-color-pink{
  background-color: $color-primary-pink;
}
.bg-color-yellow{
  background-color: $color-primary-yellow;
}
//color-secondary COLORS
.bg-color-blue{
  background-color: $color-secondary-blue;
}
.bg-color-indigo{
  background-color: $color-secondary-indigo;
}
.bg-color-orange{
  background-color: $color-secondary-orange;
}
.bg-color-purple{
  background-color: $color-secondary-purple;
}
.bg-color-red{
  background-color: $color-secondary-red;
}
.bg-color-teal{
  background-color: $color-secondary-teal;
}
.bg-color-cyan{
  background-color: $color-secondary-cyan;
}
.color-gray-1{
  color:$color-neutral-gray-1;
}
.color-gray-2{
  color:$color-neutral-gray-2;
}
.color-gray-3{
  color:$color-neutral-gray-3;
}
.color-gray-4{
  color:$color-neutral-gray-4;
}
.color-gray-5{
  color:$color-neutral-gray-5;
}
.color-gray-6{
  color:$color-neutral-gray-6;
}
.color-gray-7{
  color:$color-neutral-gray-7;
}
.color-gray-8{
  color:$color-neutral-gray-8;
}
.color-gray-9{
  color:$color-neutral-gray-9;
}
.color-gray-10{
  color:$color-neutral-gray-10;
}

//color-primary COLORS
.color-beige{
  color: $color-primary-beige;
}
.color-dark{
  color: $color-primary-dark;
}
.color-green{
  color: $color-primary-green;
}
.color-light{
  color: $color-primary-light;
}
.color-pink{
  color: $color-primary-pink;
}
.color-yellow{
  color: $color-primary-yellow;
}
//color-secondary COLORS
.color-blue{
  color: $color-secondary-blue;
}
.color-indigo{
  color: $color-secondary-indigo;
}
.color-orange{
  color: $color-secondary-orange;
}
.color-purple{
  color: $color-secondary-purple;
}
.color-red{
  color: $color-secondary-red;
}
.color-teal{
  color: $color-secondary-teal;
}
.border-gray-1{
  border-color:$color-neutral-gray-1;
}
.border-gray-2{
  border-color:$color-neutral-gray-2;
}
.border-gray-3{
  border-color:$color-neutral-gray-3;
}
.border-gray-4{
  border-color:$color-neutral-gray-4;
}
.border-gray-5{
  border-color:$color-neutral-gray-5;
}
.border-gray-6{
  border-color:$color-neutral-gray-6;
}
.border-gray-7{
  border-color:$color-neutral-gray-7;
}
.border-gray-8{
  border-color:$color-neutral-gray-8;
}
.border-gray-9{
  border-color:$color-neutral-gray-9;
}
.border-gray-10{
  border-color:$color-neutral-gray-10;
}

//color-primary COLORS
.border-color-beige{
  border-color: $color-primary-beige;
}
.border-color-dark{
  border-color: $color-primary-dark;
}
.border-color-green{
  border-color: $color-primary-green;
}
.border-color-light{
  border-color: $color-primary-light;
}
.border-color-pink{
  border-color: $color-primary-pink;
}
.border-color-yellow{
  border-color: $color-primary-yellow;
}
//color-secondary COLORS
.border-color-blue{
  border-color: $color-secondary-blue;
}
.border-color-indigo{
  border-color: $color-secondary-indigo;
}
.border-color-orange{
  border-color: $color-secondary-orange;
}
.border-color-purple{
  border-color: $color-secondary-purple;
}
.border-color-red{
  border-color: $color-secondary-red;
}
.border-color-teal{
  border-color: $color-secondary-teal;
}

