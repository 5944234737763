@import '../variables';
.rb-tab {
  border:none;

  &__list {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top:$spacing-xs;
    border:none;
  }

  &__item {
    display: block;
    vertical-align: bottom;
    text-align: center;
    background: $color-neutral-gray-8;
    color:$color-neutral-gray-1;
    width: 159px;
    height: 40px;
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
    line-height: 2.4;
    white-space: nowrap;
    margin: auto $spacing-xxs;
    cursor: pointer !important;
    border-radius:$border-radius-large $border-radius-large 0 0;
    a{
      color:$color-neutral-gray-1;
      text-decoration: none;
    }

    &:hover,
    &.-active {
      background:$color-neutral-gray-10;
      cursor: default !important;
    }
  }


}
