@import '~src/styles/variables';

$alert-path: '~src/assets/icons/alert/';
$menu-path: '~src/assets/icons/menu/';
$button-path: '~src/assets/icons/button/';

[class^="rb-icon-"], [class*=" rb-icon-"], .rb-icon {
  background-size: contain;
  width: 24px;
  height: 24px;
  display: inline-block;
}

@mixin setIcon($iconPath) {
  background-color: #fff;
  -webkit-mask-image: url("#{$iconPath}");
  mask-image: url("#{$iconPath}");
}

/*Alert Icon*/

.rb-icon-info {
  @include setIcon("#{$alert-path}information.svg");
  background-color: $color-secondary-blue;
}

.rb-icon-alert {
  @include setIcon("#{$alert-path}close-circle-fill.svg");
  background-color: $color-secondary-red;
}

.rb-icon-success {
  @include setIcon("#{$alert-path}checkbox-circle.svg");
  background-color: $color-secondary-green;
}

.rb-icon-warning {
  @include setIcon("#{$alert-path}warning.svg");
  background-color: $color-secondary-orange;
}

/*Menu Icon*/
.rb-icon-truck {
  @include setIcon("#{$button-path}truck.svg");
}

.rb-icon-car {
  @include setIcon("#{$button-path}car-light.svg");
}

.rb-icon-search {
  @include setIcon("#{$menu-path}search.svg");
}

.rb-icon-baked {
  @include setIcon("#{$menu-path}baked-items.svg");
}

.rb-icon-cake {
  @include setIcon("#{$menu-path}cake.svg");
}

.rb-icon-cake-slice {
  @include setIcon("#{$menu-path}cake-slice.svg");
}

.rb-icon-candles {
  @include setIcon("#{$menu-path}candles.svg");
}

.rb-icon-dessert {
  @include setIcon("#{$menu-path}dessert.svg");
}

/* Button Icon*/
.rb-icon-delete-back {
  @include setIcon("#{$button-path}delete-back.svg");
}

.rb-icon-arrow-down {
  @include setIcon("#{$button-path}arrow-down.svg");
}

.rb-icon-arrow-up {
  @include setIcon("#{$button-path}arrow-up.svg");
}

.rb-icon-arrow-left {
  @include setIcon("#{$button-path}arrow-left.svg");
}

.rb-icon-arrow-right {
  @include setIcon("#{$button-path}arrow-right.svg");
}

.rb-icon-phone {
  @include setIcon("#{$button-path}phone.svg");
}

.rb-icon-edit {
  @include setIcon("#{$button-path}edit.svg");
  cursor: pointer;
}

.rb-icon-mail {
  @include setIcon("#{$button-path}mail.svg");
}

.rb-icon-web {
  @include setIcon("#{$button-path}window.svg");
}

.rb-icon-store {
  @include setIcon("#{$button-path}store.svg");
}

.rb-icon-bank-card {
  @include setIcon("#{$button-path}bank-card.svg");
}

.rb-icon-money-dollar-circle {
  @include setIcon("#{$button-path}money-dollar-circle.svg");
}

.rb-icon-delete-bin {
  @include setIcon("#{$button-path}delete-bin.svg");
}

.rb-icon-add {
  @include setIcon("#{$button-path}add.svg");
}

.rb-icon-amex {
  @include setIcon("#{$button-path}amex.svg");
}

.rb-icon-paypal {
  @include setIcon("#{$button-path}paypal.svg");
}

.rb-icon-visa {
  @include setIcon("#{$button-path}visa.svg");
}

.rb-icon-shopify {
  @include setIcon("#{$button-path}shopify.svg");
}

.rb-icon-gift {
  @include setIcon("#{$button-path}gift.svg");
}

.rb-icon-mastercard {
  @include setIcon("#{$button-path}mastercard.svg");
}

.rb-icon-square {
  @include setIcon("#{$button-path}square.svg");
}

.rb-icon-currency {
  @include setIcon("#{$button-path}currency.svg");
}

.rb-icon-todo {
  @include setIcon("#{$button-path}todo.svg");
}

.rb-icon-add-circle {
  @include setIcon("#{$button-path}add-circle.svg");
}

.rb-icon-more {
  @include setIcon("#{$button-path}more.svg");
}

.rb-icon-men {
  @include setIcon("#{$button-path}men-light.svg");
}

.rb-icon-women {
  @include setIcon("#{$button-path}women.svg");
}

.rb-icon-both {
  @include setIcon("#{$button-path}both.svg");
}

.rb-icon-notapplicable {
  @include setIcon("#{$button-path}notapplicable.svg");
}

.rb-icon-restart {
  @include setIcon("#{$button-path}restart.svg");
}

.rb-icon-calendar {
  @include setIcon("#{$button-path}calendar.svg");
}

.rb-icon-file-search {
  @include setIcon("#{$button-path}file-search.svg");
}

.rb-icon-file-chart {
  @include setIcon("#{$button-path}file-chart.svg");
}

.rb-icon-truck-16 {
  @include setIcon("#{$button-path}truck-16.svg");
  width: 16px !important;
  height: 16px !important;
}

.rb-icon-image-16 {
  @include setIcon("#{$button-path}image-16.svg");
  width: 16px !important;
  height: 16px !important;
}

.rb-icon-stack-16 {
  @include setIcon("#{$button-path}stack-16.svg");
  width: 16px !important;
  height: 16px !important;
}

.rb-icon-printer {
  @include setIcon("#{$button-path}printer.svg");
}

.rb-icon-user {
  @include setIcon("#{$button-path}user.svg");
  width: 16px !important;
  height: 16px !important;
}

.rb-icon-check-circle {
  @include setIcon("#{$button-path}checkbox-circle.svg");
}

.rb-icon-login-circle {
  @include setIcon("#{$button-path}login-circle.svg");
}

.rb-icon-map-pin {
  @include setIcon("#{$button-path}map-pin.svg");
}

.rb-icon-mobile {
  @include setIcon("#{$button-path}mobile.svg");
}

.rb-icon-lock-password {
  @include setIcon("#{$button-path}lock-password.svg");
}

.rb-icon-key {
  @include setIcon("#{$button-path}key.svg");
}

.rb-icon-swap {
  @include setIcon("#{$button-path}swap-line.svg");
}

.rb-icon-exchange-funds{
  @include setIcon("#{$button-path}exchange-funds-line.svg");
}

.rb-icon-error{
  @include setIcon("#{$button-path}error.svg");
}

.rb-icon-x{
  @include setIcon("#{$button-path}x-altx-alt.svg");
}

.rb-icon-checkbox{
  @include setIcon("#{$button-path}checkbox-marked-circle.svg");
}
